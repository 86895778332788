<template>
  <div>
    <div class="flex flex-row">
      <div>
        <h1
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal">{{
            `Companies / ${currentCompany.name} / Subscription and Invites /`
          }}</span>
          Subscription Renewal
        </h1>
      </div>
      <div class="ml-auto">
        <div
          class="flex flex-row space-x-2"
        >
          <Button
            text="Cancel Renewal"
            size="medium"
            v-if="!renewalFormDetails.isDefault"
            @click.native="
            cancelSubscription({organizationId: currentCompany.id})
            "
          />
          <Button
            :text="renewalFormDetails.isDefault ? 'Create' : 'Update'"
            size="medium"
            :disabled="!hasChanges && !hasSelectedProducts"
            @click.native="
              updateSubscription({
                organizationId: currentCompany.id,
                action: 1,
              })
            "
          />
        </div>

      </div>
    </div>
    <div>
      <SubscriptionRenewalDetailsForm/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Button from "@/components/Button/Button.vue";
import SubscriptionRenewalDetailsForm from "../components/SubscriptionRenewalDetailsForm/index.vue";

export default {
  name: "SubscriptionRenewal",
  components: {
    Button,
    SubscriptionRenewalDetailsForm,
  },
  data: () => ({
    initialState: null,
  }),
  methods: {
    ...mapActions({
      getSubscriptionRenewalDetails: "subscriptions/getSubscriptionRenewalDetails",
      updateSubscription: "subscriptions/updateSubscriptionRenewal",
      cancelSubscription: "subscriptions/cancelSubscriptionRenewal"
    }),
  },
  computed: {
    ...mapState({
      renewalFormDetails: (state) => state.subscriptions.renewalForm,
      currentCompany: (state) => state.companies.currentCompany,
    }),
    hasSelectedProducts() {
      return (
        this.renewalFormDetails.selectedProducts.filter(
          (product) => product.isSelected
        ).length > 0
      );
    },
    hasChanges() {
      const form = this.renewalFormDetails;
      const state = this.initialState;
      form.reminders.sort();
      state?.reminders.sort();
      if (form.lead !== state?.lead) return true;
      if (form.pastResults !== state?.pastResults) return true;
      if (form.startDate !== state?.startDate) return true;
      if (form.endDate !== state?.endDate) return true;
      if (
        form.reminders.length !== state?.reminders.length &&
        !(JSON.stringify(form.reminders) === JSON.stringify(state?.reminders))
      )
        return true;
      if (JSON.stringify(form) !== JSON.stringify(state)) return true;
      return false;
    },
  },
  async mounted() {
    await this.getSubscriptionRenewalDetails(this.$route.params.companyId);
    this.initialState = JSON.parse(JSON.stringify(this.renewalFormDetails));
  },
};
</script>
