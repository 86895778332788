<template>
  <div class="bg-white rounded shadow p-2 pt-3">
    <p class="font-headers font-bold text-mdl text-grey-light mb-2">
      Setup the Customer’s Subscription Renewal Options
    </p>
    <div class="flex flex-col px-2 mt-3">
      <SubscriptionDates />
      <div class="w-full h-line bg-grey-light-4 my-4" />
      <SubscriptionEmail />
      <div class="w-full h-line bg-grey-light-4 my-4" />
      <SubscriptionProducts />
    </div>
  </div>
</template>
<script>
import SubscriptionDates from "./components/SubscriptionRenewalDates.vue";
import SubscriptionEmail from "./components/SubscriptionRenewalEmail.vue";
import SubscriptionProducts from "./components/SubscriptionRenewalProducts.vue";
import { mapActions } from 'vuex';

export default {
  name: 'SubscriptionRenewalDetailsForm',
  components: {
    SubscriptionDates,
    SubscriptionEmail,
    SubscriptionProducts
  },
  methods: {
    ...mapActions({
      resetSubscriptionsState: 'subscriptions/resetSubscriptionsState'
    })
  },
  beforeDestroy() {
    this.resetSubscriptionsState();
  }
}
</script>
